<template>
  <div class="p-grid p-fluid dashboard">
    <div class="p-col-12 p-md-6">
      <Panel header="Informações do Usuário." style="height: 100%">
        <div style="padding: 5px">
          <div class="p-fluid p-formgrid p-grid">
            <!--  CADASTRO DE PERMISSOES DO USUARIO -->
            <div class="p-col-12" style="padding: 30px">
              <div class="p-text-bold">
                <h5>Dados Básicos</h5>
              </div>
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="nome">Nome</label>
                  <InputText
                    id="nome"
                    :class="{ 'p-invalid': submitted && !usuario.nome }"
                    v-model="usuario.nome"
                  />
                  <small class="p-invalid" v-if="submitted && !usuario.nome"
                    >Nome é Obrigatorio.</small
                  >
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="email"
                    :class="{ 'p-invalid': submitted && !usuario.email }"
                    v-model="usuario.email"
                  />
                  <small class="p-invalid" v-if="submitted && !usuario.email"
                    >Email é Obrigatorio.</small
                  >
                </div>
              </div>

              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <label for="tipo">Grupo</label>
                  <Dropdown
                    id="tipo"
                    :options="tipoUsuario"
                    optionLabel="tipo"
                    optionValue="id"
                    :class="{ 'p-invalid': submitted && !usuario.tipo }"
                    v-model="usuario.tipo"
                  />
                  <small class="p-invalid" v-if="submitted && !usuario.tipo"
                    >Tipo é Obrigatorio.</small
                  >
                </div>
                <div class="p-field p-col-12 p-md-6">
                  <label for="status">Status</label><br />
                  <InputSwitch id="status" v-model="usuario.status" />
                </div>
              </div>
            </div>
            <!--  CADASTRO DE PERMISSOES DO USUARIO -->
          </div>
        </div>

        <div style="padding: 5px">
          <div class="p-fluid p-formgrid p-grid">
            <!--  CADASTRO DE PERMISSOES DO USUARIO -->
            <div class="p-col-12" style="padding: 30px">
              <div class="p-text-bold">
                <h5>Lista de Permissões</h5>
              </div>
              <Listbox
                v-model="usuario.permissoes"
                :options="permissao"
                :multiple="true"
                :filter="true"
                optionLabel="descricao"
                listStyle="max-height:250px"
                filterPlaceholder="DIGITE O NOME DA PERMISSÃO."
                emptyFilterMessage="Nenhum Resultado..."
                emptyMessage="Nenhum Resultado..."
              >
                <template #option="slotProps">
                  <div class="product-list-detail">
                    <i class="pi pi-unlock product-category-icon p-mr-3"> </i>
                    <span class="product-category">
                      {{ slotProps.option.id }}
                      {{ slotProps.option.descricao }}.</span
                    >
                  </div>
                </template>
              </Listbox>
              <hr />
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-9"></div>
                <div class="p-field p-col-12 p-md-3">
                  <Button
                    label="Atualizar"
                    class="p-button-raised"
                    icon="pi pi-check"
                    @click="saveUser"
                  />
                </div>
              </div>
            </div>
            <!--  CADASTRO DE PERMISSOES DO USUARIO -->
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>

import AuthService from "@/service/Auth/AuthService";
import UsersService from "@/service/Users/UsersService";
import PermissionService from "@/service/Permissoes/PermissionService";

export default {
  data() {
    return {
      usuario: {
        nome: null,
        email: null,
        senha: null,
        status: true,
        permissoes: null,
        tipo: null,
      },
      tipoUsuario: [{ tipo: "Administrador" }, { tipo: "Operador" }],
      selecionaUsuario: null,
      submitted: false,
      token: null,
      permissao: null,
    };
  },
  authService: null,
  permissionService: null,
  usuarioService: null,
  created() {
    this.authService = new AuthService();
    this.permissionService = new PermissionService();
    this.usuarioService = new UsersService();
  },
  mounted() {
    this.token = this.$Token.jwtDecode();
    this.findByUsuarioId();
    this.findByPermissoes();
  },
  methods: {
    findByUsuarioId() {
      this.usuarioService
        .findById(this.token.id)
        .then((data) => {
          this.usuario = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findByPermissoes() {
      this.permissionService
        .findAll()
        .then((data) => {
          this.permissao = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    saveUser() {
      this.submitted = true;
      this.usuario.senha = "!@#$DASD1234!@#";
      this.usuarioService
        .update(this.usuario.id, this.usuario)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Usuario foi alterado com sucesso.",
              life: 6000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
          this.$toast.add({
            severity: "error",
            summary: "Erro!",
            detail: "Erro ao atualizar usuário, entre em contato com CTIS.",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>